import React from 'react'
import { graphql } from "gatsby"
import BackgroundImg from 'gatsby-background-image'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import ExitToIcon from '@material-ui/icons/ExitToApp'

import Layout from '../../../../components/layout'
import SEO from '../../../../components/seo'
import Contents from '../../../../components/contents'
import Paragraphs from '../../../../components/paragraph'
import Heading from '../../../../components/heading'
import HeroBox from '../../../../components/herobox'
import Gallery from '../../../../components/gallery/isawaonsen/sightseeings'

const useStyles = makeStyles(theme => ({
    root: {

    },
    rootContainer: {
        padding: 0,
    },
    galleryContainer: {
        padding: '15px',
    },
    hero: {
        padding: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '80vh',
        textAlign: 'center',
        color: '#ffffff',
    },
    heading1: {
        backgroundColor: 'rgba(72,189,152,.9)',
        padding: '20px;'
    },
    paragraphs: {
        paddingTop: '30px;'
    },
    paragraph: {
        backgroundColor: 'rgba(0,0,0,.5)',
    },
    button: {
        textAlign: 'right',
        marginBottom: '1.45rem',
        paddingRight: '30px',
    }
}));

const IsawaonsenStationFruitsPage = ({ data }) => {
    const classes = useStyles();
    const language = 'ja';
    const slug = 'station/isawaonsen/sightseeings';
    const station = data.railroadJson;
    const stationName = station.stationName[language];
    const stationAddress = station.address[language];
    let page = station.pages.filter(function(page, index) {
        if (page.slug === slug) {
            return true;
        }
    });
    page = page[0][language];
    const sections = page.sections;
    const heroImage = data.hero.childImageSharp.fluid;
    const gallery1 = 'railroad/'+station.prefecture_name+'/'+station.name+'/sightseeings/gallery';
    return (
        <Layout language={language} slug={slug}>
            <SEO title={stationName} />
            <HeroBox
                image={heroImage}
                stationName={stationName}
                descriptions={page.descriptions}
                language={language}
            />
            <Container component="section" className={classes.rootContainer}>
                <Box component="article">{
                    sections.map((section, i) => {
                        if (!section.isChild) {
                            if ('article' === section.type) {
                                return (
                                            <section key={i}>
                                                <Heading title={section.title} isChild={section.isChild} />
                                                <Contents language={language} contents={section.contents} />
                                            </section>
                                        )
                            } else if ('link' === section.type) {

                            } else if ('gallery' === section.type) {
                                if (gallery1 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery idxSection={i} language={page.language} items={items} />
                                        </section>
                                    )
                                } else {

                                }
                            } else {

                            }
                        } else {
                            if ('article' === section.type) {
                                return (
                                            <section key={i}>
                                                <Heading title={section.title} isChild={section.isChild} />
                                                <Contents language={language} contents={section.contents} />
                                            </section>
                                        )
                            } else if ('link' === section.type) {

                            } else if ('gallery' === section.type) {
                                if (gallery1 === section.gallery.directory) {
                                    const items = page.sections[i].gallery.items;
                                    return (
                                        <section className={classes.galleryContainer} key={i}>
                                            <Gallery idxSection={i} language={page.language} items={items} />
                                        </section>
                                        // <pre>{JSON.stringify(items, null, 4)}</pre>
                                    )
                                } else {
                                    return <pre>{JSON.stringify(gallery1, null, 4)}</pre>
                                }
                            } else {

                            }
                        }
                    })
                }</Box>
            </Container>
        </Layout>
        // <pre>{JSON.stringify(sections, null, 4)}</pre>
    )
}

export const query = graphql`
  {
    railroadJson(name: {eq: "isawaonsen"}) {
      id
      heroImage
      address {
        en {
          address1
          address2
          city
          language
          prefecture
        }
        ja {
          address1
          address2
          city
          language
          prefecture
        }
        zh_hans {
          address1
          address2
          city
          prefecture
          language
        }
        zh_hant {
          address1
          address2
          city
          language
          prefecture
        }
      }
      name
      lon
      lat
      zip
      stationName {
        en
        ja
        zh_hans
        zh_hant
      }
      route_id
      prefecture_name
      prefecture_id
      pages {
        path
        slug
        fileName
        ja {
          descriptions
          language
          subtitle
          title
          sections {
            isChild
            link
            subtitle
            title
            type
            gallery {
              directory
              items {
                fileName
                title
              }
            }
            contents {
              paragraphs
              type
              link {
                title
                url
              }
              image {
                fileName
              }
            }
          }
        }
      }
    }
    hero:file(relativePath: {eq: "railroad/yamanashi/isawaonsen/sightseeings/hero.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1000, maxWidth: 1000, jpegQuality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IsawaonsenStationFruitsPage